<template>
  <div>
    <b-button v-b-modal.createQuestModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success" block>
      สร้าง
    </b-button>

    <b-modal v-model="modal" size="lg" id="createQuestModal" title="สร้าง">
      <div class="row">
        <div class="col-8">
          <b-form-group label="เวลาหมด:" label-for="close_at">
            <flat-pickr id="close_at" v-model="form.close_at" :config="closeAtConfig" class="form-control" />
          </b-form-group>
        </div>
      </div>

      <div class="row" v-for="(game, index) in form.games">
        <div class="col-4">
          <b-form-group :label="`ค่าย`" label-for="game_product_id">
            <b-form-select v-model="game.product_id" :options="gameOptions" text-field="product_name" value-field="id"
              @change="productChange"></b-form-select>
          </b-form-group>games/games/:id
        </div>
        <div class="col-4">
          <b-form-group :label="`สล็อท`" label-for="game_id">
            <b-form-select v-model="form.game_id" :options="games" text-field="game_name"
              value-field="id"></b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-end">
        <b-button @click="addQuest" size="sm" variant="info">เพิ่มเกม</b-button>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
  },
  directives: {
    Ripple
  },
  props: ['gameOptions', 'games'],
  emits: ['submit', 'getProducts', 'getGames'],
  data: () => ({
    modal: false,
    form: {
      close_at: null,
      games: [
        { product_id: '', game_id: '' }
      ]
    }
  }),
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
      this.clearForm();
    },
    addQuest() {
      this.form.quests.push({
        quest_type: 'DEPOSIT',
        quest_product_id: '',
        quest_amount: 0
      })
    },
    productChange(e) {
      if (this.form.game_category_id === 3) {
        this.$emit('getGames', e)
      }
    },
    cancel() {
      this.modal = false;
      this.clearForm();
    },
    clearForm() {
      this.form = {
        reward_type: 'MONEY',
        reward: 0,
        quests: [
          { quest_type: 'DEPOSIT', quest_amount: 0, quest_product_id: '' }
        ]
      }
    }
  }
}
</script>